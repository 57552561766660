/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'es', name: 'Es', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.credgenics.com';

export const themeColorStorageKey = '__theme_color';
export const isMultiColorActive = true;
export const isDarkSwitchActive = true;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = true;
