import { takeLatest } from "redux-saga/effects";
import { GET_USER_ACCESS_INFO, GET_USER_COMPANY_PERMISSIONS, GET_USER_TEAM } from "../actions";
import {
  handleGetUserAccessInfo,
  handleGetUserCompanyPermissions,
  handleGetUserTeam,
} from "./handlers/user";

export default function* rootSaga() {
  yield takeLatest(GET_USER_ACCESS_INFO, handleGetUserAccessInfo);
  yield takeLatest(GET_USER_COMPANY_PERMISSIONS, handleGetUserCompanyPermissions);
  yield takeLatest(GET_USER_TEAM, handleGetUserTeam);
}
