import {
  SET_USER_ACCESS_INFO,
  SET_USER_COMPANY_PERMISSIONS,
  SET_USER_TEAM,
  SET_USER_PROFESSION,
  SET_COMPANY_ADMIN_PROFILE_ID,
  SET_ACTIVE_CAMPAIGN_ID,
} from "../actions";

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_ACCESS_INFO:
      return { ...payload, assigned_companies: payload.assigned_companies_dict };

    case SET_USER_COMPANY_PERMISSIONS:
      return { ...state, permissions: payload };

    case SET_COMPANY_ADMIN_PROFILE_ID:
      return { ...state, company_admin_profile_id: payload };

    case SET_USER_TEAM:
      return { ...state, team: payload };

    case SET_USER_PROFESSION:
      return { ...state, profession: payload };
    case SET_ACTIVE_CAMPAIGN_ID:
      return { ...state, activeCampaignId: payload };

    default:
      return { ...state };
  }
};
