import { call, put } from "redux-saga/effects";
import {
  getUserAccessInfo,
  getUserCompanyPermissions,
  getUserTeam,
} from "../../../services/userService";
import {
  setUserAccessInfo,
  setUserCompanyPermissions,
  setUserTeam,
  setUserProfession,
  setCompanyAdminProfileId,
} from "../../actions";

export function* handleGetUserAccessInfo() {
  try {
    const { data } = yield call(getUserAccessInfo);
    yield put(setUserAccessInfo(data));
  } catch (error) {
    console.error(error);
  }
}

export function* handleGetUserCompanyPermissions(action) {
  const { companyId, profileId, callBack, setCompanyAdminId } = action.payload;
  try {
    const data = yield call(getUserCompanyPermissions, [companyId, profileId]);
    yield put(setUserCompanyPermissions(data.profile_details.permissions));
    if (setCompanyAdminId) yield put(setCompanyAdminProfileId(data.profile_details?.profile_id));
    callBack({
      data: data.profile_details.permissions,
      profileId: data.profile_details?.profile_id,
    });
  } catch (error) {
    console.debug(error);
  }
}

export function* handleGetUserTeam(action) {
  const { userId, companyId } = action.payload;

  try {
    const { team, profession } = yield call(getUserTeam, [userId, companyId]);
    yield put(setUserTeam(team));
    yield put(setUserProfession(profession));
  } catch (error) {
    console.debug(error);
  }
}
