/* USER */
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const GET_USER_ACCESS_INFO = "GET_USER_PROFILE";
export const SET_USER_ACCESS_INFO = "SET_USER_PROFILE";
export const GET_USER_COMPANY_PERMISSIONS = "GET_USER_COMPANY_PERMISSIONS";
export const SET_USER_COMPANY_PERMISSIONS = "SET_USER_COMPANY_PERMISSIONS";
export const GET_USER_TEAM = "GET_USER_TEAM";
export const SET_USER_TEAM = "SET_USER_TEAM";
export const SET_USER_PROFESSION = "SET_USER_PROFESSION";
export const SET_COMPANY_ADMIN_PROFILE_ID = "SET_COMPANY_ADMIN_PROFILE_ID";
export const SET_ACTIVE_CAMPAIGN_ID = "SET_ACTIVE_CAMPAIGN_ID";

export * from "./user/actions";
