// Local dependencies
import { toast } from "react-hot-toast";
import { getEndpoint } from "../helpers/endpoints";

const baseURL = process.env[getEndpoint("REACT_APP_USER_SERVICE_ENDPOINT")];

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getUserProfile = async () => {
  const urlFetch = `${baseURL}/profile/details`;

  try {
    const response = await fetch(urlFetch, {
      method: "GET",
      headers: { ...headers, authenticationtoken: localStorage.getItem("uat") },
    });
    const { message, output } = await response.json();

    if (message === "success") return { message, output };
    return { message: "Something went wrong" };
  } catch (error) {
    return { message: "Something went wrong" };
  }
};

export const getUserAccessInfo = async () => {
  const urlFetch = `${process.env[getEndpoint("REACT_APP_USER_SERVICE_ENDPOINT")]}/company-type`;

  try {
    const authenticationtoken = localStorage.getItem("uat");
    if (!authenticationtoken) return;
    const response = await fetch(urlFetch, {
      method: "GET",
      headers: { ...headers, authenticationtoken },
    });
    const { message, data } = await response.json();

    if (response.status === 200) return { message, data };
    else if (response.status === 401) signout();
    return { message: "Something went wrong" };
  } catch (error) {
    console.debug(error, "Error occured while getting user access details");
    return { message: "Something went wrong" };
  }
};

export const getUserCompanyPermissions = async ([companyId, profileId]) => {
  let urlFetch = `${
    process.env[getEndpoint("REACT_APP_USER_SERVICE_ENDPOINT")]
  }/company/permissions?company_id=${companyId}&simplified=true`;
  if (profileId)
    urlFetch = `${
      process.env[getEndpoint("REACT_APP_USER_SERVICE_ENDPOINT")]
    }/profiles/${profileId}?company_id=${companyId}`;

  try {
    const authenticationtoken = localStorage.getItem("uat");
    const response = await fetch(urlFetch, {
      method: "GET",
      headers: { ...headers, authenticationtoken },
    });
    const { message, data } = await response.json();
    if (response.status === 200)
      return { message, ...(profileId ? data : { profile_details: data }) };
    toast.error(message);
    return { message };
  } catch (error) {
    toast.error("Something went wrong");
    return { message: "Something went wrong" };
  }
};

export const getUserTeam = async ([userId, companyId]) => {
  const urlFetch = `${baseURL}/company/users/${userId}?company_id=${companyId}`;

  const response = await fetch(urlFetch, {
    method: "GET",
    headers: { ...headers, authenticationtoken: localStorage.getItem("uat") },
  });
  const { data } = await response.json();
  const { team, profession } = data.user_details;
  return { team, profession };
};
