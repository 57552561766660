import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { storeInstance } from "./redux/store";
import { lazyLoader } from "./helpers/lazyLoader";

const App = lazyLoader(() => import(/* webpackChunkName: "App" */ "./App"), "./App");

ReactDOM.render(
  <Provider store={storeInstance}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
