import { lazy } from "react";

export const lazyLoader = (componentImport, path) =>
  lazy(async () => {
    let pageRefreshObject = JSON.parse(
      localStorage.getItem("page-has-been-force-refreshed") || "{}"
    );
    if (typeof pageRefreshObject !== "object") pageRefreshObject = {};

    const pageHasAlreadyBeenForceRefreshed = !!pageRefreshObject[path];

    try {
      const component = await componentImport();
      pageRefreshObject[path] = false;

      localStorage.setItem("page-has-been-force-refreshed", JSON.stringify(pageRefreshObject));

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // page refresh logic
        pageRefreshObject[path] = true;
        localStorage.setItem("page-has-been-force-refreshed", JSON.stringify(pageRefreshObject));
        return window.location.reload();
      }
      //throw error if error persist after updating to latest version
      throw error;
    }
  });
