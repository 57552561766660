import {
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  GET_USER_ACCESS_INFO,
  SET_USER_ACCESS_INFO,
  SET_USER_COMPANY_PERMISSIONS,
  GET_USER_COMPANY_PERMISSIONS,
  SET_USER_TEAM,
  SET_USER_PROFESSION,
  GET_USER_TEAM,
  SET_COMPANY_ADMIN_PROFILE_ID,
  SET_ACTIVE_CAMPAIGN_ID,
} from "../actions";

export const getUserProfile = () => {
  return {
    type: GET_USER_PROFILE,
  };
};

export const setUserProfile = (payload) => {
  return {
    type: SET_USER_PROFILE,
    payload,
  };
};

export const getUserAccessInfo = () => {
  return {
    type: GET_USER_ACCESS_INFO,
  };
};

export const setUserAccessInfo = (payload) => {
  return {
    type: SET_USER_ACCESS_INFO,
    payload,
  };
};

export const getUserCompanyPermissions = (payload) => {
  return {
    type: GET_USER_COMPANY_PERMISSIONS,
    payload,
  };
};

export const setUserCompanyPermissions = (payload) => {
  return {
    type: SET_USER_COMPANY_PERMISSIONS,
    payload,
  };
};

export const getUserTeam = (payload) => {
  return {
    type: GET_USER_TEAM,
    payload,
  };
};

export const setUserTeam = (team) => {
  return {
    type: SET_USER_TEAM,
    payload: team,
  };
};

export const setUserProfession = (profession) => {
  return {
    type: SET_USER_PROFESSION,
    payload: profession,
  };
};

export const setCompanyAdminProfileId = (profile_id) => {
  return {
    type: SET_COMPANY_ADMIN_PROFILE_ID,
    payload: profile_id,
  };
};

export const setActiveCampaignId = (campaignId) => {
  return {
    type: SET_ACTIVE_CAMPAIGN_ID,
    payload: campaignId,
  };
};
