import country from "./getCountry";

/**
 *
 * @param {string} name Name
 *
 * @returns {string} Original
 */
export const getEndpoint = (name) => {
  let url = name + "_" + country();
  return url;
};

/**
 * @param {string} countryIsdCode
 * @returns Correct dialer url based on isd code
 */
export const getDialerEndpoint = (countryIsdCode) => {
  let url = process.env.REACT_APP_DIALER_ENDPOINT_INDIA;

  if (countryIsdCode === "+62") {
    url = process.env.REACT_APP_DIALER_ENDPOINT_INDONESIA;
  }

  return url;
};
